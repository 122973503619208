.head-container {
  padding: $padding-base-vertical $padding-base-horizontal;
  margin: $margin-large-vertical $margin-large-horizontal;

  @media (max-width: 1200px) {
    margin: $margin-large-vertical $margin-medium-horizontal;
  }
  @media (max-width: 576px) {
    margin: $margin-small-vertical $margin-small-horizontal;
  }
}

.main-container {
  padding: $padding-base-vertical $padding-base-horizontal;
  margin: $margin-large-vertical $margin-medium-horizontal;
  .list-item {
    display: flex;
    justify-content: space-around;
    .participation-item {
      flex: 4;
      margin: auto;
    }
    .swd-name {
      flex: 3;
      margin: auto;
    }
    .swd-id {
      flex: 2;
    }
    .year-item {
      flex: 1;
      text-align: center;
      margin: auto;
    }
  }

  @media (max-width: 1200px) {
    margin: $margin-large-vertical $margin-medium-horizontal;
  }
  @media (max-width: 960px) {
    margin: $margin-medium-vertical $margin-small-horizontal;
  }
  @media (max-width: 768px) {
    margin: $margin-small-vertical $margin-small-horizontal;
  }
}
.head-xl-container {
  padding: $padding-base-vertical $padding-base-horizontal;
  margin: $margin-small-vertical $margin-xs-horizontal $margin-large-vertical
    $margin-large-vertical;
}
.main-xl-container {
  padding: $padding-base-vertical $padding-base-horizontal;
  margin: $margin-large-vertical $margin-xs-horizontal;
}
.container-items-centered {
  display: flex;
  justify-content: space-around;
}

.font-small {
  font-size: $font-size-h6;
}
.box-shadow {
  box-shadow: $box-shadow-raised-xs;
}

.swd-info {
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
}

.full-container {
  margin: 16px auto;
  width: calc(100vw - 32px);
  box-sizing: border-box;

  @media (min-width: 992px) {
    width: calc(100vw - 64px);
    margin: 16px auto;
  }

  .table-borderless thead th {
    border-top: none;
  }
}

.full-container--lm {
  width: calc(100vw - 32px);
  margin: 16px auto 96px;
  box-sizing: border-box;

  @media (min-width: 992px) {
    width: calc(100vw - 234px);
    margin: 16px 32px 16px 204px;
  }
}

.simple-margin {
  @media (min-width: 992px) {
    margin: 32px
  }
}

.custom-loading-width {
  width: 75%;
  @media (min-width: 992px) {
    width: 25%;
  }
}

.flex-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  @media (min-width: 992px) {
    flex-direction: row;
  }
}