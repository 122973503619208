.fixedPlugin {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  padding: $padding-small-vertical $padding-small-horizontal;
  background: $black-lighten-bg;
  color: $white-color;
  z-index: 1031;
  border-radius: $border-radius-large;
  cursor: pointer;

  &:hover {
    background-color: lighten($black-bg, 25%);
  }

  &:focus {
    background-color: lighten($black-bg, 25%);
  }
}

.faCog {
  color: $white-color;
  padding: $padding-small-vertical $padding-small-horizontal;
}

.dropdownMenu {
  position: fixed;
  left: $none;
  padding: $padding-medium-vertical $padding-small-horizontal;
  width: 16.666667%;
  min-width: 150px;
  max-width: 170px;
  min-height: 40vh;
  border-radius: $border-radius-icon-sm;
  box-shadow: $dropdown-shadow;
  background: $white-bg;
  z-index: 99;
}

.dropdownMenuProfile {
  position: fixed;
  z-index: 99;
  left: $none;
  padding: $padding-medium-vertical $padding-small-horizontal;
  min-width: 150px;
  max-width: 170px;
  height: 24vh;
  border-radius: $border-radius-icon-sm;
  box-shadow: $dropdown-shadow;
  background: $white-bg;
}
.switchUserMenu {
  border-top: 1px solid lightgrey;
  padding-top: 1rem;
  margin-top: 1rem;
}

.skip-links-container {
  position: absolute;
  top: 0;
  left: 0;
}

.skip-link {
  position: absolute;
  top: -60px;
  left: 0;
  display: block;
  width: max-content;
  background-color: #007acc;
  color: white;
  padding: 8px 16px;
  font-size: 14px;
  text-decoration: none;
  border-radius: 4px;
  transition: top 0.3s ease-in-out;
  z-index: 0;
}

.skip-link:focus {
  top: 0;
  background-color: #007acc ;
  outline: 2px solid white;
  z-index: 9999;
}