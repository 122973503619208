.form-container {
  font-size: 0.8rem;
}
.list-group-container {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.btn-unscrollable {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}
.list-scrollable {
  margin: 8px 0;
  border: 1px solid black;
  overflow-y: scroll;
  max-height: 600px;
  width: 100%;
  border-radius: 4px;

  .list-item-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: $padding-small-vertical $padding-base-horizontal;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-width: 0 0 1px;

    .list-main-title {
      flex: 10;
      font-weight: 500;
      padding-left: $padding-small-horizontal;
      margin-top: $margin-small-vertical;
    }

    .list-sub-title {
      flex: 10;
      padding-left: $padding-medium-horizontal;
    }
    .list-simple-title {
      flex: 10;
      padding-left: $padding-large-horizontal;
    }
    .list-form {
      flex: 2;
      font-size: 0.8rem;
      text-align: right;
    }
  }
  .list-form-btn {
    flex: 2;
    justify-self: flex-end;
  }
}
