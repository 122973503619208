.box-info {
  position: relative;
  top: -2.5em;
  border: $border-thin solid $light-gray;
  border-radius: 0.25rem;
  padding: $padding-simple-vertical $padding-simple-horizontal;
  background: $white-bg;
}

.box-error {
  position: relative;
  border: $border-thin solid $light-red;
  border-radius: 0.25rem;
  padding: $padding-simple-vertical $padding-medium-horizontal;
  background: $light-red;
  font-size: 0.8rem;

  &__item {
    font-size: 1rem;
  }
}

.custom-column {
  @media (max-width: 1550px) {
    max-width: 83% !important;
  }
}
