.notes-box {
  border: $border-thin solid $light-gray;
  border-radius: 0.25rem;
  background: $smoke-bg;
  padding: $padding-simple-vertical $padding-simple-horizontal;
  box-shadow: $dropdown-shadow;
  font-size: 14px;
  z-index: 2;

  .notes-box-content {
    border: $border-thin solid $light-gray;
    border-radius: 0.25rem;
    background: $white-bg;
    padding: $padding-simple-vertical $padding-simple-horizontal;
    box-shadow: $box-shadow;
    z-index: 2;
  }
}
.box-messages {
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;

  .message-user {
    flex: 3;
    padding: $padding-small-vertical $padding-small-horizontal;
  }

  .message-content {
    flex: 8;
    border: $border-thin solid $light-gray;
    padding: $padding-small-vertical $padding-simple-horizontal;
    border-radius: 0.25rem;
    text-align: justify;
  }
  .message-delete {
    flex: 1;
    padding: $padding-xs-horizontal $padding-xs-vertical;
    margin-left: 5px;
  }
}
