.border-box {
  margin-top: $margin-small-horizontal;
  border: $border-thin solid $light-gray;
  border-radius: 0.25rem;
  background: $smoke-bg;
  padding: $padding-simple-vertical $padding-simple-horizontal;
  box-shadow: $dropdown-shadow;

  .border-box-title {
    position: relative;
    top: -1.3em;
    margin-left: 1em;
    display: inline;
    background-color: $white-bg;
    border: $border-thin solid $light-gray;
    border-radius: 0.25rem;
    padding: $padding-small-vertical $padding-small-horizontal;
    box-shadow: $dropdown-shadow;
  }
  .border-box-content {
    display: flex;
    flex-wrap: wrap;
  }
}

.btn-bg-white {
  background-color: $white-bg;
}
