html {
  position: relative;
  min-height: 100%;
}

.custom-layout {
  padding-bottom: 80px;
}

.navbar__container {
  padding: 8px 16px;

  @media (min-width: 992px) {
    padding: 8px 60px;
  }
}