.flex-center {
  display: flex;
  justify-content: center;
  margin: 20vh 8px 0;
}

.login-card {
  max-width: 20rem;
  min-height: 30vh;
  box-shadow: $box-shadow-raised-sm;

  .login-img {
    position: absolute;
    border-radius: 50%;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    box-shadow: $logo-shadow;
  }
}
