.breadcrumb-item a {
  color: rgb(32, 121, 147);
  font-weight: 300;
  text-decoration: none;
}

.breadcrumb-item a:hover {
  text-decoration: underline;
}

.breadcrumb-item.active {
  color: rgb(96, 93, 93);
  font-weight: 500;
}
