// Accessible outline-danger button
.btn-danger-accessible {
  color: #D60505 !important;
  border-color: #D60505 !important;
  background-color: white !important;

  &:hover,
  &.active {
    background-color: #D60505 !important;
    color: #ffffff !important; 
    border-color: #D60505 !important;
  }

  &:focus {
    outline: none !important;
    box-shadow: 0 0 0 0.25rem rgba(155, 28, 36, 0.5) !important;
  }
}

// Accessible outline-info button
.btn-info-accessible {
  color: #1E70B3 !important;
  border-color: #1E70B3 !important;
  background-color: white !important;

  &:hover,
  &.active {
    background-color: #1E70B3 !important;
    color: #ffffff !important;
    border-color: #1E70B3 !important;
  }

  &:focus {
    outline: none !important;
    box-shadow: 0 0 0 0.25rem rgba(0, 86, 179, 0.5) !important;
  }
}
