.generated-form-main-container {
  margin: $margin-small-vertical $padding-small-horizontal;

  .generated-form-checkbox {
    position: relative !important;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
  }

  .table-wrapper {
    max-height: 650px;
    overflow-y: auto;
  }

  .table-wrapper table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }

  .table-wrapper thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .table-head th {
    border: none;
    padding: 12px 8px 1px !important;
  }

  .table-head tr {
    box-shadow: 0px 1px 0px lightgray;
  }

  td {
    padding: 8px 2px !important;
  }

  td div {
    min-width: fit-content;
  }

  .unsaved-row {
    background-color: rgba(245, 89, 61, 0.1);
  }
}
